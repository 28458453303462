import { RequiringActionFeatureType } from '@paid-ui/enums/requiring-action';
import { SecurePaidRequestType } from '@paid-ui/enums/secure-paid';
import { requiringActionManager, saveRequiringActions } from '@paid-ui/models/requiring-action';
import { userManager } from '@paid-ui/models/user';
import { type RequiringAction } from '@paid-ui/types/requiring-actions';
import { request } from '@paid-ui/utils/axios';
import { useQuery } from '@tanstack/react-query';
import { useUpdateEffect } from 'ahooks';
import { useEffect } from 'react';
import { useSnapshot } from 'valtio/react';

type GetRequiringActionsResponse = {
  data: {
    requiredActions: RequiringAction[];
  };
} | null;

const fixAction = (action: RequiringAction) => {
  if (action.data?.securePaidRequestType === SecurePaidRequestType.UNSECURE) {
    return {
      ...action,
      featureItemType: RequiringActionFeatureType.UNSECURE_PAID_REQUEST,
    };
  }
  return action;
};

export const useGetRequiringActions = () => {
  const { forceUpdate } = useSnapshot(requiringActionManager);
  const { disableContractActions } = useSnapshot(userManager);
  const enabled = !disableContractActions;

  const query = useQuery({
    enabled,
    refetchOnWindowFocus: true,
    refetchInterval: 15_000,
    queryKey: ['GetRequiringActions'],
    queryFn: async () => {
      const { data } = await request.get<GetRequiringActionsResponse>('/ras/actions');
      return data?.data.requiredActions ?? [];
    },
  });

  useUpdateEffect(() => {
    if (disableContractActions || query.isFetching) {
      return;
    }
    query.refetch();
  }, [forceUpdate]);

  useEffect(() => {
    const actions = query.data ?? [];
    saveRequiringActions(actions.map(fixAction));
  }, [query.data]);

  return query;
};
